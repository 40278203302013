import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1920.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1920.000000) scale(0.100000,-0.100000)">
          <path d="M2550 11345 c-8 -2 -49 -8 -90 -15 -405 -63 -779 -305 -1015 -655
-172 -256 -256 -534 -256 -845 0 -192 23 -329 82 -496 169 -480 568 -849 1054
-974 164 -42 268 -54 435 -47 303 12 525 84 785 254 168 109 322 262 429 425
l38 56 -35 79 c-20 43 -50 118 -68 166 l-31 88 -52 -103 c-69 -140 -153 -254
-260 -354 -110 -103 -194 -160 -328 -224 -229 -108 -513 -145 -764 -99 -312
57 -621 260 -797 522 -137 203 -205 412 -214 652 -9 231 24 397 117 592 66
140 138 241 246 348 163 161 353 270 572 327 109 29 129 31 297 32 187 1 221
-3 385 -51 265 -76 531 -281 687 -528 77 -121 151 -318 173 -459 6 -38 15
-146 20 -240 14 -264 59 -445 160 -646 211 -417 591 -707 1065 -812 149 -33
435 -33 589 0 403 86 749 320 971 654 l37 57 -20 43 c-33 71 -68 160 -87 218
-23 73 -31 79 -45 34 -33 -99 -138 -259 -244 -371 -289 -306 -718 -447 -1133
-372 -156 28 -346 106 -481 198 -167 113 -328 301 -417 486 -86 179 -112 291
-125 548 -18 357 -95 601 -273 860 -61 90 -231 272 -315 337 -272 211 -600
322 -947 319 -71 -1 -137 -3 -145 -4z"/>
<path d="M5230 11331 c-58 -10 -132 -26 -165 -36 -82 -24 -252 -93 -297 -122
-21 -12 -40 -23 -43 -23 -3 0 -45 -27 -93 -59 -147 -98 -282 -231 -389 -379
-35 -48 -63 -92 -63 -97 0 -6 18 -51 40 -100 21 -49 49 -122 62 -162 13 -40
25 -73 28 -73 3 0 13 21 22 48 20 56 101 194 157 266 85 110 188 205 303 280
142 92 272 147 438 182 127 28 392 26 517 -4 252 -60 433 -160 614 -341 147
-147 242 -297 303 -481 40 -121 55 -218 66 -407 11 -212 29 -322 76 -463 44
-133 96 -241 172 -356 70 -107 84 -124 177 -222 218 -230 500 -384 820 -448
129 -26 437 -26 555 0 322 69 560 197 786 421 138 137 211 236 294 400 102
204 149 384 157 615 10 285 -35 494 -162 745 -209 414 -594 710 -1050 806
-121 26 -354 35 -481 19 -358 -44 -669 -201 -917 -460 -93 -97 -207 -244 -207
-268 0 -5 17 -47 39 -93 21 -46 49 -119 62 -161 13 -43 27 -78 32 -78 4 0 7 5
7 11 0 19 53 123 109 214 64 104 239 287 339 354 363 243 807 288 1204 122 78
-33 223 -118 281 -167 133 -108 274 -280 342 -414 108 -214 154 -476 126 -719
-76 -650 -659 -1139 -1308 -1097 -328 21 -585 137 -813 365 -119 120 -201 236
-259 370 -75 170 -98 279 -111 521 -14 277 -54 446 -155 655 -75 156 -157 269
-294 406 -227 226 -459 353 -770 420 -139 30 -408 35 -551 10z"/>
<path d="M8085 10545 c-171 -46 -269 -116 -326 -231 -21 -42 -24 -63 -24 -154
0 -87 4 -113 22 -152 27 -59 97 -130 160 -161 94 -47 164 -65 404 -102 182
-28 259 -85 259 -192 0 -112 -62 -171 -211 -201 -165 -32 -337 14 -430 116
-24 26 -49 56 -55 67 -9 17 -16 15 -109 -32 -55 -28 -101 -55 -102 -60 -2 -5
19 -44 46 -85 107 -164 274 -238 536 -238 273 0 459 88 542 257 26 54 28 66
28 178 0 111 -2 124 -28 177 -31 64 -97 127 -170 164 -65 33 -208 70 -345 89
-160 23 -233 47 -272 92 -30 34 -32 40 -28 96 4 55 8 63 45 98 52 48 121 69
232 69 127 0 251 -46 308 -114 l25 -30 76 38 c43 21 87 44 100 52 l22 15 -30
43 c-67 98 -172 165 -311 200 -81 20 -292 21 -364 1z"/>
<path d="M2120 9830 l0 -680 125 0 125 0 0 275 0 275 340 0 340 0 0 -275 0
-275 125 0 125 0 0 680 0 680 -125 0 -125 0 0 -285 0 -284 -340 -3 -340 -3 0
288 0 287 -125 0 -125 0 0 -680z"/>
<path d="M4870 9829 l0 -680 348 3 c330 4 350 5 417 27 113 37 184 80 261 157
78 79 139 188 170 304 24 89 24 280 1 375 -60 241 -229 415 -464 474 -74 19
-110 21 -407 21 l-326 0 0 -681z m721 412 c223 -91 314 -397 192 -641 -45 -87
-109 -145 -200 -181 -52 -20 -80 -23 -260 -27 l-203 -4 0 442 0 441 208 -3
c188 -3 212 -6 263 -27z"/>


        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
